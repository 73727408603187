<style lang="scss" scoped>

.message-body {
  width: 100%;
  z-index: 20;

  .message-word {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 8px;
    color: #ffffff;
  }

  .message-content {
    width: 100%;
    height:1023px;
    background: #02758a;

    .aside-head {
      .message-search {
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #8f8f8f;
        padding: 16px 0 16px 16px;
        box-sizing: border-box;

        .search-input {
          background-color: #064442 !important;
          border-radius: 6px;

          :deep(.el-input__wrapper) {
            background-color: #064442;
            box-shadow: none !important;
            color: #fff;
          }

          :deep(.el-input__inner) {
            background-color: #064442;
            color: #fff;
          }
        }
      }

      .person-list {
        height: 50vh;
        border-right: 1px solid #8f8f8f;
        border-bottom: 1px solid #8f8f8f;
        padding-top: 10px;
        box-sizing: border-box;
        overflow: auto;

        .person-li-active {
          background-color: #098680;
        }

        .person-li {
          padding: 0 5px;
          height: 42px;
          margin-bottom: 8px;
          color: #e0d8e7;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            color: #fff;
          }

          .img-bg {
            width: 38px;
            height: 38px;
            background: linear-gradient(
                217deg,
                rgba(249, 146, 13),
                rgba(252, 177, 40) 70.71%
              ),
              linear-gradient(127deg, rgba(251, 162, 27), rgba(241, 170, 48) 70.71%),
              linear-gradient(336deg, rgba(244, 207, 78), rgba(254, 206, 63) 70.71%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;

            .live-status {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #086d68;
              display: flex;
              align-items: center;
              justify-content: center;

              .live-start {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #8fc400;
              }

              .live-end {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #B6B6B6;
              }
            }
          }

          img {
            width: 32px;
            height: 32px;
            display: flex;
            border-radius: 50%;
          }

          .person-name {
            width: 70%;
            overflow: hidden;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-left: 10px;
            word-break: break-all;
          }
        }
      }

      .message-kefu {
        display: flex;
        align-items: center;
        height: 80px;
        box-sizing: border-box;
        padding: 0 5px;
        border-right: 1px solid #8f8f8f;
        border-bottom: 1px solid #8f8f8f;

        .kefu-icon {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background-color: #ffe400;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;

          img {
            width: 26px;
            height: 26px;
          }
        }

        font-size: 14px;
        color: #ffffff;
      }
    }

    .main {
      padding: 0;

      .main-head {
        height: 74px;
        border-bottom: 1px solid #8f8f8f;
        box-sizing: border-box;

        .main-body {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .head-information {
          box-sizing: border-box;
          padding-left: 20px;
          display: flex;
          align-items: center;
          color: #fff;

          .img-bg {
            width: 38px;
            height: 38px;
            background: linear-gradient(
                217deg,
                rgba(249, 146, 13),
                rgba(252, 177, 40) 70.71%
              ),
              linear-gradient(127deg, rgba(251, 162, 27), rgba(241, 170, 48) 70.71%),
              linear-gradient(336deg, rgba(244, 207, 78), rgba(254, 206, 63) 70.71%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;

            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }

            .live-status {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #086d68;
              display: flex;
              align-items: center;
              justify-content: center;

              .live-start {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #8fc400;
              }

              .live-end {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #B6B6B6;
              }
            }
          }

          p {
            margin-left: 5px;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }

      .main-start {
        margin-right: 10px;

        p {
          padding: 6px 10px;
          background-color: #ddb60a;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
        }
      }

      .main-content {
        height: 50vh;
        box-sizing: border-box;
        border-bottom: 1px solid #8f8f8f;

        .main-tip {
          padding-top: 25%;
          color: #fff;
          display: flex;
          flex-direction: column;
          text-align: center;
        }
      }

      .main-footer {
        height: 80px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #8f8f8f;

        .footer-input {
          width: 400px;
          height: 48px;

          :deep(.el-textarea__inner) {
            resize: none !important;
            background-color: #064442;
            color: #fff;
          }
        }

        .icon-y {
          font-size: 20px;
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
  }
}

:deep(.el-textarea__inner:focus) {
  box-shadow: none !important;
}

:deep(.el-textarea__inner) {
  box-shadow: none !important;
}

:deep(.el-input__count) {
  background: transparent !important;
}

.chat-body {
  height: 100%;
  overflow: auto;
  padding: 20px 20px;
  box-sizing: border-box;

  .chat-consultant {
    float: left;
    margin-bottom: 10px;
    color: #fff;
    width: 80%;
    height: auto;
    background-color: #064442;
    border-radius: 10px;
    position: relative;
    margin-bottom: 25px;

    &::after {
      position: absolute;
      content: "";
      top: 7px;
      box-sizing: border-box;
      left: -19px;
      height: 20px;
      width: 20px;
      border: 10px solid;
      border-color: transparent #064442 transparent transparent;
    }

    .chat-txt-my {
      padding: 10px;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: justify;

      .chat-my-img {
        width: 70%;
        height: 70%;
      }
    }
  }

  .chat-in {
    float: right;
    margin-bottom: 25px;
    box-sizing: border-box;
    color: #fff;
    width: 80%;
    height: auto;
    background-color: #064442;
    border-radius: 10px;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 7px;
      box-sizing: border-box;
      right: -19px;
      height: 20px;
      width: 20px;
      border: 10px solid;
      border-color: transparent transparent transparent #064442;
    }

    .chat-txt-my {
      padding: 10px;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: justify;

      .chat-my-img {
        width: 70%;
        height: 70%;
      }
    }
  }
}

:deep(.el-upload-list--picture-card) {
  .el-upload-list__item {
    background-color: transparent !important;
    border: none !important;
  }

  .el-upload-list__item-status-label {
    display: none !important;
  }
}

:deep(.el-dialog) {
  background-color: transparent !important;
}
.itemActive {
  margin-top: 5px;
}
</style>
<template>
  <div class="masked-body" style="border-left: 1px solid #8f8f8f">
    <div class="message-body">
      <div class="message-content">
        <el-container>
          <el-aside width="240px" class="aside-head">
            <div class="message-search">
              <el-input
                class="search-input"
                v-model="search"
                placeholder="Please input"
                clearable
                type="text"
              />
            </div>
            <ul class="person-list">
              <li
                :class="
                  item.id == liActiveid ? 'person-li person-li-active' : 'person-li'
                "
                v-for="(item, index) in upList"
                :key="index"
                @click="changeLiActive(item)"
              >
                <div class="img-bg">
                  <img
                    :src="
                      item.avatar
                        ? ImageUrl + item.avatar
                        : require('@/assets/default-user.png')
                    "
                  />
                  <div class="live-status">
                    <div class="live-start" v-if="item.status !== '0'"></div>
                    <div class="live-end" v-else></div>
                  </div>
                </div>
                <!-- <el-badge :value="12" class="itemActive" > -->
                <div class="person-name">{{ item.nickname }}</div>
                <el-badge :value="item.msgNum == 0 ? '' : item.msgNum" class="itemActive">
                </el-badge>
              </li>
            </ul>
            <div class="message-kefu">
              <div class="kefu-icon">
                <img src="@/assets/icon-kefu.png" alt="" />
              </div>
              <span>Start chatting with customer service</span>
            </div>
          </el-aside>
          <!-- —————————————————————————————————————————————————————— -->
          <el-main class="main">
            <div class="main-head">
              <div v-if="liActive" class="main-body">
                <div class="head-information">
                  <div class="img-bg">
                    <img
                      :src="
                        liActive.avatar
                          ? ImageUrl + liActive.avatar
                          : require('@/assets/default-user.png')
                      "
                    />
                    <div class="live-status">
                      <div class="live-start" v-if="liActive.status !== '0'"></div>
                      <div class="live-end" v-else></div>
                    </div>
                  </div>
                  <p>{{ liActive.nickname }}</p>
                </div>
                <!-- <div class="main-start" @click="CallClick">
                                    <p>Start a private conversation</p>
                                </div> -->
              </div>
            </div>
            <div class="main-content">
              <div class="main-tip" v-if="liActive && messageList.length == 0">
                <h1>You have no messages from Amenitylntuitive</h1>
                <h1>Start chatting now!</h1>
              </div>
              <div v-else class="chat-body" ref="msglist">
                <div v-for="(item, index) in messageList" :key="index">
                  <div
                    style="position: relative"
                    class="chat-in my-message"
                    v-if="item.userType == '2'"
                  >
                    <div
                      style="
                        color: bisque;
                        position: absolute;
                        top: -14px;
                        right: 0px;
                        font-size: 12px;
                      "
                    >
                      {{ ZTime(item.createTime) }}
                    </div>
                    <div class="chat-txt-my" v-if="item.message">
                      {{ item.message }}
                    </div>
                    <div class="chat-txt-my" v-if="item.imgUrl">
                      <ImageUpload
                        :imgUrl="[item.imgUrl]"
                        :disabled="true"
                        :tipTxt="false"
                        :limit="1"
                      />
                    </div>
                  </div>
                  <div
                    style="position: relative"
                    class="chat-consultant my-message"
                    v-if="item.userType == '1'"
                  >
                    <div
                      style="
                        color: bisque;
                        position: absolute;
                        top: -14px;
                        left: 0px;
                        font-size: 12px;
                      "
                    >
                      {{ ZTime(item.createTime) }}
                    </div>
                    <div class="chat-txt-my" v-if="item.message">
                      {{ item.message }}
                    </div>
                    <div class="chat-txt-my" v-if="item.imgUrl">
                      <ImageUpload
                        :imgUrl="[item.imgUrl]"
                        :disabled="true"
                        :tipTxt="false"
                        :limit="1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-footer" v-if="liActive">
              <el-input
                class="footer-input"
                v-model="messageVal"
                maxlength="250"
                @keydown.enter="postfaChatsend(1, $event)"
                placeholder="Please input"
                show-word-limit
                type="textarea"
              />
              <!-- <svg-icon class="icon-y" icon="liwu"></svg-icon>
                            <svg-icon class="icon-y" icon="tupian" @click="uploadImage"></svg-icon> -->
              &nbsp;&nbsp;&nbsp;&nbsp;
              <el-button color="#ffe933" @click="postfaChatsend(1)">Send</el-button>
            </div>
          </el-main>
        </el-container>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="uploadStatus"
    title="Please select an image to upload"
    width="30%"
    :before-close="handleClose"
  >
    <div style="width: 100%; display: flex; align-items: center; justify-content: center">
      <ImageUpload
        :imgUrl="messageImgVal"
        :tipTxt="false"
        :limit="1"
        @update:imgUrl="updateimgUrl"
      />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="uploadStatus = false">Cancel</el-button>
        <el-button type="primary" @click="imgConfirm"> Confirm </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, inject, onMounted, watch, nextTick } from "vue";
import request from "@/utils/request.js";
import ImageUpload from "@/components/ImageUpload/index.vue";
import { ElMessage } from "element-plus";
import { getToken } from "@/utils/auth";
import bus from "vue3-eventbus";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const socket = inject("socket");
const messageImgVal = ref("");
const search = ref("");
const messageVal = ref("");
const liActive = ref(null);
const liActiveid = ref(null);
const messageList = ref([]);
const msglist = ref("");
onMounted(() => {
  // if(window.innerWidth<769){
  //     console.log("PPP{{-=----------}}")
  //     router.push({
  //         path:"/mobleMsg",
  //         query:{
  //             type:"1"
  //         }
  //     })
  //     return
  // }
  console.log(route.path, "pppppp");
  getUpList();
  // let id = null;
  bus.on("onMessage", (e) => {
    let id = window.localStorage.getItem("yhId");
    console.log(e, "eee");
    searchList();
    if (liActive.value && route.path == "/profileInfo/message") {
      for (let q of upList.value) {
        if (q.id == e.userId && id == e.userId) {
          getChatInfo(e.userId);
        }
      }
    }
  });
});
watch(search, (val) => {
  searchList();
});

watch(
  () => route.path,
  (n) => {
    if (n !== "/profileInfo/message") {
      window.localStorage.removeItem("yhId");
    }
  }
);
const upList = ref([]);
const CallClick = () => {
  let token = getToken();
  if (token) {
    const ws = socket("1", token);
    let msg = {
      type: 1,
      from: token,
      to: liActiveid.value,
      msg: "VideoCall",
    };
    ws.send(JSON.stringify(msg));
  }
};

const ZTime = (time) => {
  const diff = new Date().getTimezoneOffset() * 60 * 1000;
  let timestamp = new Date(time).getTime() - diff;
  // 根据毫秒数构建 Date 对象
  let date = new Date(timestamp);
  // 格式化日期
  let dateTime = date.toLocaleString();
  dateTime = dateTime.substring();
  return dateTime;
};
function closeModelVal() {
  emit("showModelValChange", false);
}
function changeLiActive(item) {
  window.localStorage.setItem("yhId", item.id);
  liActive.value = item;
  liActiveid.value = item.id;
  getChatInfo(item.id);
}
async function getUpList() {
  /* &isRecommend=true  &hasChat=true */
  const { code, rows } = await request.get(
    "/system/FaUser/web/list?pageSize=10000&hasChat=true"
  );
  if (code == 200) {
    upList.value = rows;
  }
  // console.log(rows);
}
async function searchList() {
  const { code, rows } = await request.get(
    `/system/FaUser/web/list?pageSize=10000&hasChat=true${
      search.value ? "&nickname=" + search.value : ""
    }`
  );
  if (code == 200) {
    upList.value = rows;
  }
  // console.log(rows, "rows");
}
async function getChatInfo(targetId) {
  // console.log(targetId, "顾问-----idididi");
  const { code, data } = await request.get(
    "/system/faChat/info?pageSize=10000000&targetId=" + targetId
  );
  if (code == 200) {
    if (data) {
      // data.infos.forEach(item => {
      //     item.userType = '2'
      // })
      messageList.value = data.infos;
    } else {
      messageList.value = [];
    }
    nextTick(() => {
      msgScroll();
    });
    // console.log("messageList", messageList.value);
    getUpList();
  } else {
    messageList.value = [];
    ElMessage("Server error.");
  }
}
async function postfaChatsend(type, e) {
  if (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }
  let data;
  if (type == 1) {
    if (!messageVal.value) {
      ElMessage("Input cannot be empty.");
      return;
    }
    data = {
      targetId: liActiveid.value,
      message: messageVal.value,
    };
  }
  if (type == 2) {
    if (!messageImgVal.value) {
      ElMessage("Please select an image to upload.");
      return;
    }
    data = {
      targetId: liActiveid.value,
      imgUrl: messageImgVal.value,
    };
  }
  const { code } = await request.post("/system/faChat/send", data);
  if (code == 200) {
    if (type == 1) {
      messageVal.value = "";
    }
    if (type == 2) {
      messageImgVal.value = null;
      uploadStatus.value = false;
    }
    getChatInfo(liActiveid.value);
  } else {
    ElMessage("Server error.");
  }
}
function msgScroll() {
  if (msglist.value && msglist.value.scrollHeight) {
    msglist.value.scrollTop = msglist.value.scrollHeight;
  }
}
const uploadStatus = ref(false);
function handleClose() {
  uploadStatus.value = false;
}
function uploadImage() {
  uploadStatus.value = true;
  // console.log("上传图片");
}
function updateimgUrl(e) {
  messageImgVal.value = e;
}
function imgConfirm() {
  postfaChatsend(2);
}
</script>
